/*--------------------------------------------------------------
2. header
--------------------------------------------------------------*/


/* 2.2 navigation */
#header {
	position: fixed;
	width: 50%;
	top: 0;
	left: 0;
	bottom: 0;
	padding: 110px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	z-index: 99;

	@media (max-width: 1600px) {
		padding: 80px;
	}
	@media (max-width: 1400px) {
		padding: 40px;
	}
	@media (max-width: 991px) {
		width: 0;
		left: -100%;
		z-index: 9999;
	}
}

@media (min-width: 992px) {
	.navbar-expand-lg .navbar-nav {
		flex-direction: column;
	}
}


.wpo-site-header {
	/* navigation open and close btn hide for width screen */
	/* style for navigation less than 992px */
	/*navbar collaps less then 992px*/
	/*** cart-search-contact ***/
	background-color: transparent;
}

.wpo-site-header .navigation {
	background-color: transparent;
	margin-bottom: 0;
	padding: 0;
	border: 0;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	-o-border-radius: 0;
	-ms-border-radius: 0;
	border-radius: 0;
}

.navbar-header {
	padding-left: 0;

	.logo {
		font-weight: 450;
		font-size: 40px;
		line-height: 51px;
		color: $white;

		&:hover {
			color: $white;
		}
	}
}


.wpo-site-header .navigation>.container {
	position: relative;
}


.wpo-site-header {
	.row {
		width: 100%;
	}

	.hide-logo{
		display: none;
	}
}


@media (max-width: 991px) {
	.wpo-site-header {
		position: fixed;
		right: 0;
		width: 100%;
		top: 0;
		background: #fff;

		.hide-logo{
			display: block;
			position: absolute;
			left: 5px;
			top: 16px;
			text-align: left;

			.logo{
				font-weight: 450;
				font-size: 40px;
				line-height: 51px;
				color: $theme-primary-color;
			}
		}

		.navigation {
			padding: 35px 0;
		}
	}


}

.wpo-site-header #navbar {
	-webkit-transition: all 0.5s;
	-moz-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	transition: all 0.5s;
}

.wpo-site-header #navbar>ul li a:hover,
.wpo-site-header #navbar>ul li a:focus {
	text-decoration: none;
	color: $theme-primary-color;
}

@media screen and (min-width: 992px) {
	.wpo-site-header #navbar {
		/*** hover effect ***/
		justify-content: start;
		display: unset !important;

		.nav {
			padding: 150px 0;

			@media(max-width:1600px){
                padding: 80px 0;
			}
			@media(max-width:1400px){
                padding: 50px 0;
			}
		}
	}

	.wpo-site-header #navbar li {
		position: relative;
	}

	.wpo-site-header #navbar>.mobail-menu-inner ul>li>a:before {
		position: absolute;
		left: 0;
		top: 50%;
		width: 0px;
		height: 2px;
		background: $white;
		content: "";
		opacity: 0;
		visibility: hidden;
		transition: all .3s;
		border-radius: 3px;
	}

	.wpo-site-header #navbar>.mobail-menu-inner ul>li>a:hover,
	.wpo-site-header #navbar>.mobail-menu-inner ul>li>a.active {
		padding-left: 30px;
	}

	.wpo-site-header #navbar>.mobail-menu-inner ul>li>a:hover:before,
	.wpo-site-header #navbar>.mobail-menu-inner ul>li>a.active:before {
		opacity: 1;
		visibility: visible;
		width: 20px;
	}

	.wpo-site-header #navbar>.mobail-menu-inner ul>li>.sub-menu>.menu-item-has-children>a {
		position: relative;
	}

	.wpo-site-header #navbar>.mobail-menu-inner ul>li>.sub-menu>.menu-item-has-children>a:before {
		font-family: "themify";
		content: "\e649";
		font-size: 11px;
		font-size: 0.73333rem;
		position: absolute;
		right: 15px;
		top: 58%;
		-webkit-transform: translateY(-50%);
		-moz-transform: translateY(-50%);
		-o-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	.wpo-site-header #navbar>.mobail-menu-inner ul>li:hover>.sub-menu {
		top: 100%;
		visibility: visible;
		opacity: 1;
	}

	.wpo-site-header #navbar .sub-menu>li:hover>.sub-menu {
		left: 100%;
		visibility: visible;
		opacity: 1;
	}
}

.wpo-site-header .container-fluid {
	padding-right: 0;
}

@media (max-width: 991px) {
	.wpo-site-header {
		/* class for show hide navigation */
	}

	.wpo-site-header .container {
		width: 100%;
		max-width: 100%;
	}

	.wpo-site-header .navbar-header button span {
		background-color: #fff;
		width: 20px;
		display: block;
		height: 2px;
		margin-bottom: 5px;
	}

	.wpo-site-header .navbar-header button span:last-child {
		margin: 0;
	}
}

.wpo-site-header #navbar {
	ul {
		list-style: none;
	}
}

@media (max-width: 991px) and (max-width: 991px) {
	.wpo-site-header #navbar {
		top: 0;
		visibility: visible;
		opacity: 1;
	}
}

@media (max-width: 991px) {
	.wpo-site-header #navbar>ul {
		z-index: 101;
	}

	.wpo-site-header #navbar ul a {
		color: #000;
	}

	.wpo-site-header #navbar ul a:hover,
	.wpo-site-header #navbar ul li.current a {
		color: $theme-primary-color;
	}

	.wpo-site-header #navbar .close-navbar .ti-close {
		position: relative;
		top: 1px;
	}

	.wpo-site-header .navbar-toggler .first-angle,
	.wpo-site-header .navbar-toggler .last-angle {
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}

	.wpo-site-header .x-close .middle-angle {
		opacity: 0;
	}

	.wpo-site-header .x-close .first-angle {
		position: absolute;
		-webkit-transform: rotate(-44deg);
		top: 16px;
		left: 10px;
	}

	.wpo-site-header .x-close .last-angle {
		-webkit-transform: rotate(44deg);
		position: absolute;
		top: 16px;
		right: 10px;
	}
}

@media (max-width: 767px) {
	.wpo-site-header .navbar-header .navbar-brand {
		font-size: 24px;
	}

	.wpo-site-header #navbar .navbar-nav {
		margin: 0;
	}
}

@media (max-width: 991px) {
	.wpo-site-header .navbar-collapse.collapse {
		display: none;
	}

	.wpo-site-header .navbar-collapse.collapse.in {
		display: block;
	}

	.wpo-site-header .navbar-header .collapse,
	.wpo-site-header .navbar-toggle {
		display: block;
	}

	.wpo-site-header .navbar-header {
		float: none;
		position: relative;
		z-index: 99;
		text-align: center;
	}

	.wpo-site-header .navbar-right {
		float: none;
	}

	.wpo-site-header .navbar-nav {
		float: none;
	}

	.wpo-site-header .navbar-nav>li {
		float: none;
	}
}


@media (max-width: 991px) {
	.page-wrapper {
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}

	.body-overlay:before {
		content: "";
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.9);
		position: absolute;
		left: 0;
		top: 0;
		z-index: 10;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		-o-transition: all 0.3s;
		-ms-transition: all 0.3s;
		transition: all 0.3s;
	}
}

.header-search-area {
	background: #fff;
	width: 100%;
	padding: 100px 0;
	text-align: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 999;
}

.header-search-area h3 {
	font-size: 45px;
	margin: 0 0 1em;
}

.header-search-area form {
	position: relative;
}

.header-search-area input {
	height: 50px;
	font-size: 18px;
	font-size: 1.2rem;
	border-radius: 0;
	border: 0;
	border-bottom: 1px solid #d0d0d0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

.header-search-area input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	border-color: $theme-primary-color;
}

.header-search-area form button {
	background: transparent;
	border: 0;
	font-size: 20px;
	font-size: 1.33333rem;
	position: absolute;
	right: 0;
	bottom: 10px;
}

.header-search-area form button:focus {
	border: none;
	outline: none;
}

.header-search-area .close-form {
	position: absolute;
	right: 35px;
	top: 35px;
	bottom: auto;
	padding-bottom: 5px;
	border-bottom: 1px solid;
}

.header-search-area .close-form button {
	background: transparent;
	border: 0;
	outline: 0;
	font-size: 13px;
	font-size: 0.86667rem;
	text-transform: uppercase;
	color: #000;
	letter-spacing: 2px;
	z-index: 99;
}

.header-search-area .close-form button:focus {
	border: 0;
	outline: 0;
}



.wpo-site-header {

	.navigation {
		margin-bottom: 0;
		padding: 0;
		border: 0;
		@include rounded-border(0);

		>.container {
			position: relative;
		}

		@include media-query(991px) {
			padding: 35px 0;
		}
	}

	#navbar {
		@include transition-time(0.5s);

		.mobail-menu-inner {

			>ul li a:hover,
			>ul li a.active,
			>ul li a:focus {
				text-decoration: none;
				color: $white;
			}

			// style for widther screen
			@include widther(992px) {
				li {
					position: relative;
				}

				>ul>li>a {
					font-size: 16px;
					padding: 10px 0;
					display: inline-block;
					color: #B5B5B5;
					font-weight: 400;
					position: relative;
					text-transform: uppercase;
					cursor: pointer;
				}

				>ul .sub-menu {
					background-color: #fff;
					box-shadow: 0px 2px 20px 0px rgba(62, 65, 159, 0.09);
					width: 225px;
					position: absolute;
					padding: 20px 0;
					left: 0;
					top: 110%;
					z-index: 10;
					visibility: hidden;
					opacity: 0;
					@include transition-time(0.3s);
				}

				>ul>li .sub-menu a {
					font-size: 16px;
					font-size: calc-rem-value(16);
					display: block;
					padding: 10px 15px;
					color: $dark-gray;
					position: relative;
					overflow: hidden;
					font-weight: 700;

					&:after {
						position: absolute;
						left: 15px;
						bottom: 4px;
						width: 0px;
						height: 2px;
						content: "";
						background: $theme-primary-color;
						transition: all .3s;
						opacity: 0;
						visibility: hidden;
					}

					&:hover,
					&.active {
						&:after {
							width: 40px;
							opacity: 1;
							visibility: visible;
						}
					}
				}

				>ul>li>.sub-menu .sub-menu {
					left: 110%;
					top: 0;
				}

				>ul>li>.sub-menu>.menu-item-has-children>a {
					position: relative;

					&:before {
						font-family: "themify";
						content: "\e649";
						font-size: 11px;
						font-size: calc-rem-value(11);
						position: absolute;
						right: 15px;
						top: 50%;
						@include translatingY();
					}
				}

				/*** hover effect ***/
				>ul>li:hover>.sub-menu {
					top: 100%;
					visibility: visible;
					opacity: 1;
				}

				.sub-menu>li:hover>.sub-menu {
					left: 100%;
					visibility: visible;
					opacity: 1;
				}
			}

		}

		@include media-query(991px) {

			.mobail-menu-inner {
				>ul>li a {
					display: block;
					font-size: 15px;
					font-size: calc-rem-value(15);
					color: $white;
					font-weight: 600;

					&:hover,
					&.active {
						color: $white;
					}
				}

				>ul .sub-menu>li:last-child {
					border-bottom: 0;
				}

				>ul>li>.sub-menu a {
					padding: 8px 15px 8px 45px;
				}

				>ul>li>.sub-menu .sub-menu a {
					padding: 8px 15px 8px 65px;
				}

				>ul .menu-item-has-children>a {
					position: relative;
					color: #ddd;

					&:hover,
					&.active {
						color: #fff;
					}

					&:before {
						font-family: "themify";
						content: "\e649";
						font-size: 11px;
						font-size: calc-rem-value(11);
						position: absolute;
						right: 30px;
						top: 50%;
						@include translatingY();
						@include transition-time(0.3s);
					}

					&.rotate {
						&:before {
							transform: rotate(90deg);
							top: 29%;
						}
					}

					&.rotate {
						color: #fff;
					}
				}

			}
		}
	}

	/* navigation open and close btn hide for width screen */
	@include widther(992px) {
		.navbar-header .open-btn {
			display: none;
		}

		#navbar .close-navbar {
			display: none;
		}
	}

	.menu-close {
		display: none;
	}


	/* style for navigation less than 992px */
	@include media-query (991px) {
		.container {
			width: 100%;
		}

		.mobail-menu button {
			background-color: $theme-primary-color;
			width: 40px;
			height: 35px;
			border: 0;
			padding: 5px 10px;
			outline: 0;
			position: absolute;
			z-index: 20;
			right: 15px;
			top: 18px;

			&:focus {
				outline: none;
				box-shadow: none;
			}


			span {
				background-color: $white;
				width: 20px;
				display: block;
				height: 2px;
				margin-bottom: 5px;

				&:last-child {
					margin: 0;
				}
			}
		}

		#navbar {
			background: darken($theme-primary-color, 3);
			display: block !important;
			width: 300px;
			height: 100%;
			margin: 0;
			padding: 0;
			border: 0;
			position: fixed;
			left: -320px;
			top: 0px;
			z-index: 999999;
			opacity: 0;
			visibility: hidden;

			.navbar-header{
              text-align: left;
			  margin-bottom: 20px;
			}

			.navbar-nav {
				display: block;
			}

			.mobail-menu-inner {
				padding: 0 30px;
				height: 100%;
				overflow: auto;
				padding-bottom: 60px;

				>ul {
					position: relative;
					z-index: 101;
					margin-bottom: 20px;
				}

				ul a {
					color: $black;
					padding: 10px 0px;
					position: relative;
					transition: all .3s;

					&:before {
						position: absolute;
						left: 0;
						top: 50%;
						width: 0px;
						height: 2px;
						background: $white;
						content: "";
						opacity: 0;
						visibility: hidden;
						transition: all .3s;
						border-radius: 3px;
					}

					&:hover,
					&.active{
						padding-left: 30px;
						&:before {
							width: 20px;
							opacity: 1;
							visibility: visible;
						}
					}
				}

				ul a:hover,
				ul li.current a {
					color: $theme-primary-color;
				}

				.close-navbar {
					background-color: $white;
					width: 40px;
					height: 40px;
					color: $black;
					border: 0;
					outline: none;
					position: absolute;
					left: -41px;
					top: 90px;
					z-index: 20;

					.ti-close {
						position: relative;
						top: 1px;
					}
				}

				.theme-btn{
					@media(max-width:991px){
                       padding: 15px;
					   font-size: 12px;
					}
				}
			}


		}

		.menu-close {
			display: block;
			z-index: 99;
			background: none;
			text-align: center;
			color: $white;
			border: 0;
			text-align: center;
			margin-left: auto;
			margin-top: 30px;
			margin-right: 30px;
			margin-bottom: 30px;
			width: 40px;
			height: 40px;
			line-height: 40px;
			border: 1px solid $white;
		}

		/* class for show hide navigation */
		.slideInn {
			left: 0 !important;
			opacity: 1 !important;
			visibility: visible !important;
		}

		.navbar-toggler .first-angle,
		.navbar-toggler .last-angle {
			@include transition-time(0.3s);
		}

		.x-close .middle-angle {
			opacity: 0;
		}

		.x-close .first-angle {
			position: absolute;
			-webkit-transform: rotate(-44deg);
			top: 16px;
			left: 10px;
		}

		.x-close .last-angle {
			-webkit-transform: rotate(44deg);
			position: absolute;
			top: 16px;
			right: 10px;
		}
	}

	@include media-query(767px) {
		.navbar-header .navbar-brand {
			font-size: 24px;
		}

		#navbar .navbar-nav {
			margin: 0;
		}
	}

	.navbar-header .navbar-brand {
		img {
			max-width: 170px;
		}
	}


	/*navbar collaps less then 992px*/
	@include media-query (991px) {
		.navbar-collapse.collapse {
			display: none;
		}

		.navbar-collapse.collapse.in {
			display: block;
		}

		.navbar-header .collapse,
		.navbar-toggle {
			display: block;
		}

		.navbar-header {
			float: none;
		}

		.navbar-right {
			float: none;
		}

		.navbar-nav {
			float: none;
		}

		.navbar-nav>li {
			float: none;
		}
	}


}




@media(max-width: 440px) {
	.wpo-site-header .navbar-header .navbar-brand {
		img {
			max-width: 170px;
		}
	}
}

@media(max-width: 370px) {
	.wpo-site-header .navbar-header .navbar-brand {
		img {
			max-width: 140px;
		}
	}
}



@media(max-width: 1500px) {
	.wpo-site-header #navbar>ul>li>a {
		padding: 30px 15px;
	}


}

@media(max-width: 1400px) {
	.wpo-site-header #navbar>ul>li>a {
		padding: 30px 10px;
		font-size: 18px;
	}

}

@media(max-width: 1200px) {
	.wpo-site-header #navbar>ul>li>a {
		padding: 30px 6px;
		font-size: 16px;
	}

	.wpo-site-header .header-right .theme-btn {
		display: none;
	}
}

@media(max-width: 991px) {
	.wpo-site-header #navbar>ul>li>a {
		font-size: 15px;
	}
}

@media (max-width: 767px) {
	.header-right .theme-btn {
		padding: 20px 12px;
	}

	.wpo-site-header .header-right .close-form {
		display: none;
	}

}


@media(max-width:991px) {
	.wpo-site-header #navbar>ul>li>a {
		padding: 15px 30px;
	}
}

@media(max-width:991px) {
	.wpo-site-header {
		z-index: 99999;
		box-shadow: 0px 5px 14.25px 0.75px rgba(12, 105, 184, .10);
	}
}