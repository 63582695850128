.theme-btn,
.theme-btn-s2 {
	background: transparent;
	color: $white;
	display: inline-block;
	font-weight: 600;
	padding: 15px 45px;
	border: 1px solid transparentize($white, .8);
	text-transform: capitalize;
	-webkit-backface-visibility: hidden;
	z-index: 1;
	position: relative;
	font-size: 16px;
	font-size: calc-rem-value(16);
	transition: all .3s;
	border-radius: 0;

	&:after {
		position: absolute;
		content: '';
		top: -5px;
		left: 5px;
		width: 100%;
		height: 100%;
		background: transparent;
		z-index: -1;
		border: 1px solid transparentize($white, .8);
		transition: all .3s;
	}

	&:hover,
	&:focus,
	&:active {
		background: $white;
		color: $theme-primary-color;

		&:after {
			border-color: $white;
		}
	}

	&:hover:after {
		opacity: 1;
	}

	@include media-query(991px) {
		font-size: 18px;
		font-size: calc-rem-value(18);
	}

	@include media-query(767px) {
		padding: 12px 18px;
		font-size: 14px;
	}
}

.theme-btn-s2{
	border: 1px solid transparentize($dark-gray, .8);
	color: $theme-primary-color;

	&:after {
		border: 1px solid transparentize($dark-gray, .8);
	}

	&:hover,
	&:focus,
	&:active {
		background: $theme-primary-color;
		color: $white;

		&:after {
			border-color: $theme-primary-color;
		}
	}
}