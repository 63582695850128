.wpo-section-title,
.wpo-section-title-s2 {
	margin-bottom: 60px;

	@include media-query(991px) {
		text-align: center;
	}
	@include media-query(767px) {
		margin-bottom: 40px;
	}

	span {
		text-transform: uppercase;
		font-size: 20px;
		color: $theme-primary-color;
		font-family: $heading-font;
	}

	h2 {
		font-weight: 500;
		font-size: 40px;
		line-height: 51px;
		margin: 0;
		margin-bottom: 10px;
		position: relative;
		text-transform: capitalize;
		font-family: $heading-font;

		@include media-query(1700px) {
			font-size: 35px;
			line-height: 45px;
		}
		@include media-query(1600px) {
			font-size: 32px;
			line-height: 45px;
		}
		@include media-query(1400px) {
			font-size: 30px;
			line-height: 45px;
		}


		@include media-query(767px) {
			font-size: 32px;
			line-height: 40px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}

	}

	p {
		font-size: 18px;
	}

	.sec-title-btn{
        text-align: right;

        @include media-query(991px) {
            text-align: center;
            margin-top: 15px;
        }

        .theme-btn{
            @include media-query(1200px) {
                padding: 15px 30px;
            }
			cursor: pointer;
        }

		.theme-btn-s2{
			cursor: pointer;
		}
    }

}

.wpo-section-title-s2 {
	h2 {
		color: $white;

		@include media-query(1500px) {
			font-size: 32px;
		}

		@include media-query(330px) {
			font-size: 30px;
		}
	}

	p {
		color: $text-color2;

		@include media-query(1500px) {
			font-size: 16px;
		}
	}
}